import { AuthFacade } from '@account/core/facades';
import { EMPTY } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { LanguagesFacade } from './facades/common';
import { EnvironmentService, FeatureFlagService, OrySessionService, TokenService } from './services';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function onAppInit(
  environmentsService: EnvironmentService,
  tokenService: TokenService,
  orySessionService: OrySessionService,
  authFacade: AuthFacade,
  languagesFacade: LanguagesFacade,
  featureFlagService: FeatureFlagService
): () => Promise<any> {
  languagesFacade.initializeLanguage();
  featureFlagService.resolveQueryString();

  // eslint-disable-next-line arrow-body-style
  return (): Promise<any> => {
    return (
      environmentsService
        .loadEnvironment()
        .pipe(
          switchMap(() => {
            // check for a valid sbp token
            const token = tokenService.getToken();
            if (token) {
              return authFacade.loginWithToken(token).pipe(
                take(1),
                catchError(() => authFacade.logout())
              );
            }

            if (featureFlagService.flagActivated('ory')) {
              // check for a valid ory session
              return orySessionService.isLoggedIn().pipe(
                switchMap((token: string | null) => {
                  if (token && orySessionService.isVerified()) {
                    return authFacade.loginWithOryJwtSessionToken(token).pipe(
                      take(1),
                      catchError(() => authFacade.logout())
                    );
                  }
                  return EMPTY;
                })
              );
            }

            return EMPTY;
          })
        )
        // neither firstValueFrom nor lastValueFrom seem to work properly here, therefore let deprecation exist for now
        .toPromise()
    );
  };
}
