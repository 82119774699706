import { basketActions, sessionActions } from '@account/core/store/actions';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { UserAccountsGateway } from '../../../gateways/auth';
import { CompanyRegistrationData, SbpException, UserAccount, UserAccountRegistration } from '../../../models';
import { LoggingService } from '../../../services';
import * as companyActions from '../../company/company-meta.actions';
import * as navigationActions from '../../navigation/navigation.actions';
import { RootState } from '../../root.state';
import * as sessionSelectors from '../../session/session.selectors';
import * as invitationsActions from '../invitations/invitations.actions';
import * as membershipsActions from '../memberships/memberships.actions';
import * as permissionActions from '../permissions/permissions.actions';
import * as actions from './useraccount.actions';

@Injectable({
  providedIn: 'root',
})
export class UseraccountEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly userAccountsGateway = inject(UserAccountsGateway);
  createUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createUserAccount),
      map((action) => action.payload),
      switchMap((registrationData: UserAccountRegistration) =>
        this.userAccountsGateway.createUserAccount(registrationData).pipe(
          map((userAccount: UserAccount) => actions.createUserAccountSuccess({ payload: userAccount })),
          catchError((error: SbpException) => of(actions.createUserAccountFail({ payload: error })))
        )
      )
    )
  );

  getUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getUserAccount),
      map((action) => action.payload.id),
      switchMap((userAccountId: number) =>
        this.userAccountsGateway.getUserAccount(userAccountId).pipe(
          tap((userAccount: UserAccount) => {
            LoggingService.setUserAccountContextForErrorHandler(userAccount);
          }),
          map((userAccount: UserAccount) => actions.getUserAccountSuccess({ payload: userAccount })),
          catchError((error: SbpException) => of(actions.getUserAccountFail({ payload: error }))),
          takeUntil(this.actions$.pipe(ofType(sessionActions.logout)))
        )
      )
    )
  );

  getUserAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getUserAccountSuccess),
      map((action) => action.payload),
      concatMap((userAccount: UserAccount) => {
        const nextActions: Action[] = [
          navigationActions.setNavigationForUserAccount(),
          invitationsActions.getInvitations({ payload: userAccount.id }),
          membershipsActions.getMemberships({ payload: userAccount.id }),
        ];
        if (userAccount.selectedMembership) {
          nextActions.push(companyActions.getCompany({ payload: userAccount.selectedMembership.company.id }));
          nextActions.push(permissionActions.setPermissions({ payload: userAccount.selectedMembership }));
          nextActions.push(basketActions.setBasketForCompanyUserAccount());
        } else {
          nextActions.push(companyActions.noCompanyExists());
        }
        return nextActions;
      })
    )
  );

  updateUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateUserAccount),
      map((action) => action.payload),
      switchMap((userAccount: UserAccount) =>
        this.userAccountsGateway.updateUserAccount(userAccount).pipe(
          map((userAccount: UserAccount) => actions.updateUserAccountSuccess({ payload: userAccount })),
          catchError((error: SbpException) => of(actions.updateUserAccountFail({ payload: error })))
        )
      )
    )
  );

  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createCompany),
      map((action) => action.payload),
      withLatestFrom(this.store.select(sessionSelectors.getUserAccountId)),
      switchMap(([payload, userAccountId]: [{ company: CompanyRegistrationData }, number]) =>
        this.userAccountsGateway.createCompany(payload.company).pipe(
          concatMap(() => [
            sessionActions.resetCompanyAttributes(),
            companyActions.refreshCompany(),
            companyActions.refreshSignedGTC(),
            actions.getUserAccount({ payload: { id: userAccountId } }),
            navigationActions.setNavigationForCompany(),
            navigationActions.setWorkspaces()
          ]),
          catchError((error: SbpException) => of(actions.createCompanyFail({ payload: error })))
        )
      )
    )
  );
}
