import { AuthFacade, CompaniesFacade } from '@account/core/facades';
import { Company } from '@account/core/models';
import { StringUtils } from '@account/core/utils';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyOnboardingGuard {
  private readonly router = inject(Router);
  private readonly authFacade = inject(AuthFacade);
  private readonly companiesFacade = inject(CompaniesFacade);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const relativeUrl = StringUtils.trimSlashes(state.url);

    return this.canActivateIfLoggedIn(relativeUrl).pipe(
      switchMap((result: boolean | UrlTree) => {
        if (false === result || result instanceof UrlTree) {
          return of(result);
        }

        return this.companiesFacade.findCompany().pipe(
          switchMap((company: Company) => {
            if (company) {
              if (relativeUrl.includes('success') || relativeUrl.includes('process')) {
                return of(true);
              }
              return of(this.router.parseUrl('portal'));
            }

            return of(true);
          })
        );
      })
    );
  }

  private canActivateIfLoggedIn(url: string): Observable<boolean | UrlTree> {
    return this.authFacade.isLoggedIn().pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          return true;
        }
        this.authFacade.setRedirectUrl(url);
        return this.router.parseUrl('front');
      })
    );
  }
}
