import { CompaniesGateway } from '@account/core/gateways';
import { CompanyAllocations, SbpException } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import * as navigationActions from '../../navigation/navigation.actions';
import * as onboardingActions from '../../onboarding/onboarding.actions';
import { RootState } from '../../root.state';
import * as sessionActions from '../../session/session.actions';
import * as actions from './allocations.actions';

@Injectable({
  providedIn: 'root',
})
export class AllocationsEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly companiesGateway = inject(CompaniesGateway);
  getCompanyAllocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getCompanyAllocations),
      map((action) => action.payload),
      switchMap((companyId: number) =>
        this.companiesGateway.getAllocations(companyId).pipe(
          concatMap((companyAllocations: CompanyAllocations) => {
            const onboardingData = companyAllocations.isCompanyInOnboardingProcess
              ? onboardingActions.setOnboardingDataForCompany()
              : onboardingActions.resetOnboardingData();

            return [
              sessionActions.setPartnerId({
                payload: companyAllocations.isPartner ? companyAllocations.partnerId : undefined,
              }),
              sessionActions.setProducerId({
                payload: companyAllocations.isProducer ? companyAllocations.producerId : undefined,
              }),
              actions.getCompanyAllocationsSuccess({ payload: companyAllocations }),
              onboardingData,
            ];
          }),
          catchError((error: SbpException) => of(actions.getCompanyAllocationsFail({ payload: error })))
        )
      )
    )
  );

  setCompanyAllocationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getCompanyAllocationsSuccess),
      map(() => navigationActions.setNavigationForCompany())
    )
  );
}
