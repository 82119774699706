import { basketActions, navigationActions, sessionActions } from '@account/core/store/actions';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, switchMapTo, withLatestFrom } from 'rxjs/operators';

import { UserAccountsGateway } from '../../../gateways/auth';
import { CompanyMembership, SbpException, UserAccount } from '../../../models';
import * as allocationsActions from '../../company/allocations/allocations.actions';
import * as companyActions from '../../company/company/company.actions';
import * as signedGTCActions from '../../company/signed-gtc/signed-gtc.actions';
import * as supportTicketUpdateActions from '../../company/support-ticket-updates/support-ticket-updates.actions';
import { partnerStore } from '../../partner';
import { producerStore } from '../../producer';
import { RootState } from '../../root.state';
import * as sessionSelectors from '../../session/session.selectors';
import * as permissionActions from '../permissions/permissions.actions';
import * as userAccountActions from '../useraccount-meta.actions';
import { userAccountSelectors } from '../useraccount-meta.selectors';
import * as actions from './memberships.actions';

@Injectable({
  providedIn: 'root',
})
export class MembershipsEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly userAccountsGateway = inject(UserAccountsGateway);
  getMemberships$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getMemberships),
      map((action) => action.payload),
      switchMap((userAccountId: number) =>
        this.userAccountsGateway.getMemberships(userAccountId).pipe(
          map((memberships: CompanyMembership[]) => actions.getMembershipsSuccess({ payload: memberships })),
          catchError((error: SbpException) => of(actions.getMembershipsFail({ payload: error })))
        )
      )
    )
  );

  changeSelectedMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.selectMembership),
      map((action: { payload: number }) => action.payload),
      withLatestFrom(this.store.select(sessionSelectors.getUserAccountId)),
      switchMap(([id, userAccountId]: [number, number]) =>
        of(actions.selectMembership).pipe(
          switchMapTo(
            this.userAccountsGateway.changeSelectedMembership(userAccountId, id).pipe(
              concatMap((userAccount: UserAccount) => [
                sessionActions.resetCompanyAttributes(),
                navigationActions.resetNavigation(),
                navigationActions.setWorkspaces(),
                navigationActions.setNavigationForUserAccount(),
                companyActions.refreshCompany(),
                allocationsActions.refreshCompanyAllocations(),
                supportTicketUpdateActions.resetSupportTicketUpdate(),
                signedGTCActions.refreshSignedGTC(),
                sessionActions.setCompanyId({ payload: userAccount.selectedMembership.company.id }),
                userAccountActions.changeSelectedMembership({ payload: userAccount.selectedMembership }),
                companyActions.getCompany({ payload: userAccount.selectedMembership.company.id }),
                permissionActions.setPermissions({ payload: userAccount.selectedMembership }),
                partnerStore.actions.partner.resetPartner(),
                producerStore.actions.producer.resetProducer(),
                basketActions.resetBasket(),
                actions.selectMembershipSuccess(),
              ]),
              catchError((error: SbpException) => of(actions.selectMembershipFail({ payload: error })))
            )
          )
        )
      )
    )
  );

  terminateMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.terminateMembership),
      map((action: { payload: number }) => action.payload),
      withLatestFrom(this.store.select(userAccountSelectors.getUserAccount)),
      switchMap(([id, userAccount]: [number, UserAccount]) =>
        this.userAccountsGateway.terminateMembership(userAccount.id, id).pipe(
          concatMap(() => {
            const changeSelectedMembership = userAccount.selectedMembership.id === id;
            if (changeSelectedMembership) {
              return [
                companyActions.refreshCompany(),
                allocationsActions.refreshCompanyAllocations(),
                userAccountActions.getUserAccount({ payload: { id: userAccount.id } }),
              ];
            }
            return [actions.terminateMembershipSuccess({ payload: id })];
          }),
          catchError((error: SbpException) => of(actions.terminateMembershipFail({ payload: error })))
        )
      )
    )
  );
}
