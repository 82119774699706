import { CompanySupportPermissionGateway } from '@account/core/gateways';
import { SupportPermissions } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SbpException } from '../../../models';
import * as actions from './support-permissions.actions';

@Injectable({
  providedIn: 'root',
})
export class SupportPermissionsEffects {
  private readonly actions$ = inject(Actions);
  private readonly supportPermissionGateway = inject(CompanySupportPermissionGateway);
  getSupportPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getSupportPermissions),
      map((action) => action.payload),
      switchMap((companyId: number) =>
        this.supportPermissionGateway
          .getSupportPermissions(companyId)
          .pipe(
            map((supportPermissions: SupportPermissions) =>
              actions.getSupportPermissionsSuccess({ payload: supportPermissions })
            )
          )
      ),
      catchError((error: SbpException) => of(actions.getSupportPermissionsFail({ payload: error })))
    )
  );
}
