import { AuthFacade } from '@account/core/facades';
import { rootActions } from '@account/core/store/actions';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval, of } from 'rxjs';
import { catchError, concatMap, map, mapTo, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { AccessTokensGateway, UserAccountsGateway } from '../../gateways/auth';
import { LoginCredentials, LoginResultCredentials, LoginResultToken, SbpException, UserAccount } from '../../models';
import { OrySessionService, SessionCookieService, TokenService } from '../../services';
import * as invitationsActions from '../useraccount/invitations/invitations.actions';
import * as userAccountActions from '../useraccount/useraccount-meta.actions';
import * as actions from './session.actions';

@Injectable({
  providedIn: 'root',
})
export class SessionEffects {
  private readonly actions$ = inject(Actions);
  private readonly authFacade = inject(AuthFacade);
  private readonly accessTokensGateway = inject(AccessTokensGateway);
  private readonly userAccountsGateway = inject(UserAccountsGateway);
  private readonly orySessionService = inject(OrySessionService);
  private readonly sessionCookieService = inject(SessionCookieService);
  private readonly tokenService = inject(TokenService);
  loginWithCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loginWithCredentials),
      map((action) => action.payload),
      switchMap((credentials: LoginCredentials) =>
        this.accessTokensGateway.login(credentials).pipe(
          concatMap((loginResult: LoginResultCredentials) => [
            actions.loginWithCredentialsSuccess({ payload: loginResult }),
            userAccountActions.getUserAccount({ payload: { id: loginResult.userAccountId } }),
          ]),
          catchError((error: SbpException) => of(actions.loginFail({ payload: error })))
        )
      )
    )
  );

  loginWithToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loginWithToken),
      map((action) => action.payload),
      switchMap((token: string) =>
        this.accessTokensGateway.loginWithToken(token).pipe(
          map((loginResult: LoginResultToken) => actions.loginWithTokenSuccess({ payload: loginResult })),
          catchError((error: SbpException) => of(actions.loginFail({ payload: error })))
        )
      )
    )
  );

  loginWithOryJwtSessionToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loginWithOryJwtSessionToken),
      map((action) => action.payload),
      switchMap(() =>
        this.userAccountsGateway.getMe().pipe(
          map((userAccount: UserAccount) =>
            actions.loginWithOryJwtSessionTokenSuccess({ payload: { userAccountId: userAccount.id } })
          ),
          catchError((error: SbpException) => of(actions.loginFail({ payload: error })))
        )
      )
    )
  );

  loginWithTokenSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loginWithTokenSuccess),
      map((action) => action.payload),
      concatMap((loginResult: LoginResultToken) => [
        invitationsActions.invitationCheckCompleted(),
        userAccountActions.getUserAccount({ payload: { id: loginResult.userAccountId } }),
      ])
    )
  );

  loginWithOryJwtSessionTokenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loginWithOryJwtSessionTokenSuccess),
      map((action) => action.payload),
      concatMap((data: { userAccountId: number }) => [
        invitationsActions.invitationCheckCompleted(),
        userAccountActions.getUserAccount({ payload: { id: data.userAccountId } }),
      ])
    )
  );

  refreshToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loginWithCredentialsSuccess, actions.loginWithTokenSuccess),
        // every 10 minutes
        switchMapTo(interval(600000)),
        switchMap(() => this.accessTokensGateway.loginWithToken(this.tokenService.getToken()))
      ),
    { dispatch: false }
  );

  refreshOryJwtSessionToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loginWithOryJwtSessionTokenSuccess),
        // every 10 minutes
        switchMapTo(interval(600000)),
        switchMap(() => this.orySessionService.isLoggedIn())
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.logout),
      tap(() => this.orySessionService.destroyToken()),
      switchMap(() =>
        this.accessTokensGateway.logout().pipe(
          tap(() => {
            this.authFacade.resetUILogin();
            this.sessionCookieService.destroyGTCBypassCookie();
            this.tokenService.destroyToken();
          }),
          mapTo(actions.logoutSuccess()),
          catchError((error: SbpException) => {
            this.authFacade.resetUILogin();
            this.sessionCookieService.destroyGTCBypassCookie();
            this.tokenService.destroyToken();
            return of(actions.logoutFail({ payload: error }));
          })
        )
      )
    )
  );

  logoutSuccess = createEffect(() => this.actions$.pipe(ofType(actions.logoutSuccess), mapTo(rootActions.logout())));
}
