import { AclFacade, BasketFacade, CompaniesFacade } from '@account/core/facades';
import { Basket, CompanyAllocations, SupportTicketUpdates, Workspace, WorkspaceItem } from '@account/core/models';
import { EnvironmentService, FeatureFlagService } from '@account/core/services';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { map, mapTo, switchMap, take } from 'rxjs/operators';

import {
  academyWorkspace,
  companyRegistrationWorkspace,
  partnerWorkspace,
  producerWorkspace,
  settingsWorkspace,
  shopOwnerWorkspace,
  workspaces,
} from '../../components/navigation/workspaces';
import { RootState } from '../root.state';
import * as actions from './navigation.actions';
import * as selectors from './navigation.selectors';
import { NavigationState } from './navigation.state';

@Injectable({
  providedIn: 'root',
})
export class NavigationEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly aclFacade = inject(AclFacade);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly featureFlagService = inject(FeatureFlagService);
  private readonly environmentService = inject(EnvironmentService);
  private readonly basketFacade = inject(BasketFacade);

  setNavigationForUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setNavigationForUserAccount),
      // take current state once otherwise observable will not trigger correctly up on logging out and back in
      switchMap((): Observable<boolean> => this.companiesFacade.hasCompany().pipe(take(1))),
      map((hasCompany: boolean) => {
        if (this.featureFlagService.flagActivated('ory')) {
          const externalSettingsWorkspaceItems = settingsWorkspace.find(
            (item: WorkspaceItem) => item.path === '/settings/externalmyaccount'
          );
          const newExternalLink = `${this.environmentService.current.auth_ui_url}/settings`;
          if (externalSettingsWorkspaceItems && externalSettingsWorkspaceItems.externalLink !== newExternalLink) {
            externalSettingsWorkspaceItems.externalLink = newExternalLink;
          }
        }

        const navigation: Partial<NavigationState> = {};
        if (hasCompany) {
          navigation.settings = settingsWorkspace;
          navigation.companyRegistration = null;
        } else {
          navigation.companyRegistration = companyRegistrationWorkspace;
          navigation.settings = settingsWorkspace.filter(
            (workspaceItem: WorkspaceItem) => !workspaceItem.hideIfCompanyDoesNotExist
          );
        }

        return actions.setNavigationForUserAccountSuccess({
          payload: {
            hasCompany: hasCompany,
            navigation: navigation,
          },
        });
      })
    )
  );

  setNavigationForUserAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setNavigationForUserAccountSuccess),
      map((action) => action.payload),
      map((payload: { hasCompany: boolean }) => payload.hasCompany),
      switchMap((hasCompany) => {
        if (hasCompany) {
          return EMPTY;
        }
        return of(actions.setWorkspaces());
      })
    )
  );

  setNavigationForCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setNavigationForCompany),
      switchMap((): Observable<CompanyAllocations> => this.companiesFacade.getAllocations().pipe(take(1))),
      switchMap((allocations: CompanyAllocations) => {
        const accessibleProducerWorkspace$ = this.getAccessibleWorkspace(producerWorkspace).pipe(
          switchMap((workspaceItems: WorkspaceItem[]) => this.applyProducerSupportTicketUpdates(workspaceItems))
        );
        return combineLatest([
          this.getAccessibleWorkspace(academyWorkspace),
          allocations.hasShops ? this.getAccessibleWorkspace(shopOwnerWorkspace) : of([]),
          allocations.isPartner ? this.getAccessibleWorkspace(partnerWorkspace) : of([]),
          allocations.isProducer ? accessibleProducerWorkspace$ : of([]),
        ]).pipe(take(1));
      }),
      map(
        ([academyWsp, shopOwnerWsp, partnerWsp, producerWsp]: [
          WorkspaceItem[],
          WorkspaceItem[],
          WorkspaceItem[],
          WorkspaceItem[],
        ]) => {
          const navigation: Partial<NavigationState> = {};

          if (academyWsp.length > 0) {
            navigation.academy = academyWsp;
          }
          if (shopOwnerWsp.length > 0) {
            navigation.shopOwner = shopOwnerWsp;
          }
          if (partnerWsp.length > 0) {
            navigation.partner = partnerWsp;
          }
          if (producerWsp.length > 0) {
            navigation.producer = producerWsp;
          }
          return actions.setNavigationForCompanySuccess({ payload: { navigation: navigation } });
        }
      )
    )
  );

  setNavigationForCompanySuccess$ = createEffect(() =>
    this.actions$.pipe(ofType(actions.setNavigationForCompanySuccess), mapTo(actions.setWorkspaces()))
  );

  setWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setWorkspaces),
      switchMap(
        (): Observable<[boolean, boolean, boolean, boolean, boolean, boolean]> =>
          combineLatest([
            this.store.select(selectors.hasPartnerWorkspace),
            this.store.select(selectors.hasProducerWorkspace),
            this.store.select(selectors.hasShopOwnerWorkspace),
            this.store.select(selectors.hasAcademyWorkspace),
            this.store.select(selectors.hasCompanyRegistrationWorkspace),
            this.store.select(selectors.hasSettingsWorkspace),
          ]).pipe(take(1))
      ),
      switchMap(
        ([
          hasPartnerWorkspace,
          hasProducerWorkspace,
          hasShopOwnerWorkspace,
          hasAcademyWorkspace,
          hasCompanyRegistrationWorkspace,
          hasSettingsWorkspace,
        ]: [boolean, boolean, boolean, boolean, boolean, boolean]) => {
          const accessibleWorkspaces: Workspace[] = [];
          if (hasPartnerWorkspace) {
            accessibleWorkspaces.push(workspaces.find((workspace: Workspace) => 'partner' === workspace.name));
          }
          if (hasProducerWorkspace) {
            accessibleWorkspaces.push(workspaces.find((workspace: Workspace) => 'producer' === workspace.name));
          }
          if (hasShopOwnerWorkspace) {
            accessibleWorkspaces.push(workspaces.find((workspace: Workspace) => 'shopOwner' === workspace.name));
          }
          if (hasAcademyWorkspace) {
            accessibleWorkspaces.push(workspaces.find((workspace: Workspace) => 'academy' === workspace.name));
          }

          if (hasCompanyRegistrationWorkspace) {
            accessibleWorkspaces.push(
              workspaces.find((workspace: Workspace) => 'companyRegistration' === workspace.name)
            );
          }

          if (hasSettingsWorkspace) {
            accessibleWorkspaces.push(
              workspaces.find((workspace: Workspace): boolean => 'settings' === workspace.name)
            );
          }

          return this.applyAcademyWorkspaceBasketNotification(accessibleWorkspaces);
        }
      ),
      map((accessibleWorkspaces: Workspace[]) =>
        actions.setWorkspacesSuccess({ payload: { workspaces: accessibleWorkspaces } })
      )
    )
  );

  private getAccessibleWorkspace(workspace: WorkspaceItem[]): Observable<WorkspaceItem[]> {
    const accessGiven = [];
    let numberItemsAlwaysVisible = 0;
    for (const workspaceItem of workspace) {
      accessGiven.push(this.aclFacade.isAccessGrantedForLocation(workspaceItem.path));
      if (workspaceItem.alwaysVisible) {
        numberItemsAlwaysVisible++;
      }
    }

    return combineLatest([...accessGiven]).pipe(
      map((hasAccessToRoute: boolean[]) => {
        let updatedWorkspace = [];
        for (const [i, hasAccess] of hasAccessToRoute.entries()) {
          if (hasAccess) {
            updatedWorkspace.push(workspace[i]);
          }
        }

        // clear if there are only items which are alwaysVisible
        if (numberItemsAlwaysVisible === updatedWorkspace.length) {
          updatedWorkspace = [];
        }

        return updatedWorkspace;
      })
    );
  }

  private applyProducerSupportTicketUpdates(workspaceItems: WorkspaceItem[]): Observable<WorkspaceItem[]> {
    const support = workspaceItems.find((workspaceItem: WorkspaceItem) => workspaceItem.path === 'producer/support');
    if (undefined === support) {
      return of(workspaceItems);
    }
    return this.companiesFacade.getSupportTicketUpdates().pipe(
      map((supportTicketUpdates: SupportTicketUpdates) => {
        if (support) {
          const indexSupport = workspaceItems.indexOf(support);
          const supportItem = { ...support };
          supportItem.hasNotification = supportTicketUpdates?.producer?.open.length > 0 || supportTicketUpdates?.producer?.overdue.length > 0;
          if (indexSupport !== -1) {
            workspaceItems[indexSupport] = supportItem;
          }
        }

        return workspaceItems;
      })
    );
  }

  private applyAcademyWorkspaceBasketNotification(workspaces: Workspace[]): Observable<Workspace[]> {
    const academy = workspaces.find((workspace: Workspace) => workspace.name === 'academy');

    if (undefined === academy) {
      return of(workspaces);
    }

    return this.basketFacade.getStoredBasket().pipe(
      map((basket: Basket) => basket?.positions?.length > 0),
      map((hasNotification: boolean) => {
        const updatedWorkspaces = [ ...workspaces ];
        const index = updatedWorkspaces.indexOf(academy);

        updatedWorkspaces[index] = { ...academy, ...{ hasNotification } };

        return updatedWorkspaces;
      })
    );
  }
}
