import {
  ActionBarComponent,
  BannedBannerComponent,
  BrowserCompatibilityNoticeComponent,
  FooterComponent,
  NavigationComponent,
} from '@account/core/components';
import { AuthFacade, NavigationFacade, RouterFacade } from '@account/core/facades';
import { FeatureFlagService } from '@account/core/services';
import { CommonModule, Location, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import {
  AoLayoutBaseComponent,
  AoLayoutBaseContentDirective,
  AoLayoutBaseMetaDirective,
  AoLoadingComponent,
} from '@shopware/aorta';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, mapTo, mergeMap, switchMap } from 'rxjs/operators';

import { ScriptInitializationService } from './core/services/app/script-initialization.service';
import { ToastContainerComponent } from './shared/components/toast/container/toast-container.component';
import { ToastService } from './shared/components/toast/toast.service';
import { WindowScrollDirective } from './shared/directives/window-scroll.directive';

@Component({
  selector: 'account-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
  standalone: true,
  imports: [
    CommonModule,
    BannedBannerComponent,
    WindowScrollDirective,
    ToastContainerComponent,
    NavigationComponent,
    FooterComponent,
    ActionBarComponent,
    BrowserCompatibilityNoticeComponent,
    RouterOutlet,
    AoLayoutBaseComponent,
    AoLayoutBaseMetaDirective,
    AoLayoutBaseContentDirective,
    AoLoadingComponent,
  ],
})
export class AppComponent implements OnInit {
  isBanned$: Observable<boolean>;
  private readonly authFacade = inject(AuthFacade);
  private readonly routerFacade = inject(RouterFacade);
  private readonly toastService = inject(ToastService);
  private readonly navigationFacade = inject(NavigationFacade);
  private readonly location = inject(Location);
  private readonly route = inject(ActivatedRoute);
  private readonly featureFlagService = inject(FeatureFlagService);
  isSoftBanned$: Observable<boolean>;
  logoutInProgress$: Observable<boolean>;
  isSubNavExpanded$: Observable<boolean>;

  isRouteFullscreen: boolean;
  customWidth: string | null;
  isActionBarVisible = true;

  applicationState: 'initial' | 'loggedIn' | 'loggedOut' = 'initial';

  loginCompleted$ = new Subject<void>();

  constructor() {
    const scriptInitializationService = inject(ScriptInitializationService);

    registerLocaleData(localeDe);
    scriptInitializationService.initializeScripts();
  }

  ngOnInit(): void {
    this.loginCompleted$.subscribe();

    this.authFacade
      .isUILoginCompleted()
      .pipe(
        switchMap((loggedIn: boolean) => {
          if (!loggedIn) {
            return of(false);
          }
          // select workspace depending on url when page is loaded
          return this.navigationFacade.selectActiveWorkspaceByPath(this.location.path(), false);
        })
      )
      .subscribe((loggedIn) => {
        if (loggedIn) {
          this.applicationState = 'loggedIn';
          this.loginCompleted$.next();
        } else {
          this.applicationState = 'loggedOut';
        }
      });

    this.isBanned$ = this.authFacade.isBanned();
    this.isSoftBanned$ = this.authFacade.isSoftBanned();
    this.isSubNavExpanded$ = this.navigationFacade.isExpanded();

    this.toastService.enableClearOnNavigation();

    this.routerFacade
      .registerRoutingChanges()
      .pipe(
        mapTo(this.route),
        map((activatedRoute: ActivatedRoute) => {
          while (activatedRoute.firstChild) {
            activatedRoute = activatedRoute.firstChild;
          }
          return activatedRoute;
        }),
        filter((activatedRoute: ActivatedRoute) => activatedRoute.outlet === 'primary'),
        mergeMap((activatedRoute: ActivatedRoute) => activatedRoute.data)
      )
      .subscribe((data) => {
        this.isRouteFullscreen = data['fullscreen'];
        this.customWidth = data['customWidth'];
        this.isActionBarVisible = data['isActionBarVisible'] ?? true;
      });
  }

  isFeatureFlagActivated(featureFlag: string): boolean {
    return this.featureFlagService.flagActivated(featureFlag);
  }
}
