import { FriendlyCaptchaPuzzleComponent } from '@account/core/components';
import { LanguagesFacade, LocalesFacade, UserAccountsFacade } from '@account/core/facades';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, inject, OnInit, ViewContainerRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, switchMap, timer } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';

import { Language } from '../../../core/models/common/language.model';
import { Locale } from '../../../core/models/common/locale.model';
import { UserAccountRegistration } from '../../../core/models/user/user-account.model';
import { CaptchaService } from '../../../core/services';
import { AuthCardComponent } from '../../../shared/components/auth-card/auth-card.component';
import { CheckboxComponent } from '../../../shared/components/checkbox/checkbox.component';
import { PasswordMaskComponent } from '../../../shared/components/password-mask/password-mask.component';
import { SwIconComponent } from '../../../shared/components/sw-icon/sw-icon.component';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { CapsLockToggledDirective } from '../../../shared/directives/caps-lock-toggled.directive';
import { SafePipe } from '../../../shared/pipes';
import { RegistrationValidators } from '../../../shared/validators/registration.validators';
import { StringValidators } from '../../../shared/validators/string.validators';
import { RegisterCompletedComponent } from '../register-completed/register-completed.component';

@Component({
  selector: 'account-user-account-register-form',
  templateUrl: './user-account-register-form.component.html',
  styleUrl: './user-account-register-form.component.less',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    RegisterCompletedComponent,
    AsyncPipe,
    TranslateModule,
    CheckboxComponent,
    PasswordMaskComponent,
    AuthCardComponent,
    SafePipe,
    SwIconComponent,
    RouterLink,
    CapsLockToggledDirective,
  ],
})
export class UserAccountRegisterFormComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly captchaService = inject(CaptchaService);
  private readonly elementRef = inject(ElementRef);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly localesFacade = inject(LocalesFacade);
  private readonly userAccountsFacade = inject(UserAccountsFacade);
  private readonly toastService = inject(ToastService);
  private readonly languagesFacade = inject(LanguagesFacade);
  private readonly translateService = inject(TranslateService);
  private readonly cookiesService = inject(CookieService);
  form: UntypedFormGroup;
  completed = false;
  isRegistering = false;
  loaded$: Observable<boolean>;
  locales$: Observable<Locale[]>;
  isCapsLockOn: boolean;

  private captchaComponent: FriendlyCaptchaPuzzleComponent;

  ngOnInit(): void {
    const emailToPrefill = this.route.snapshot.queryParams['email'] ?? '';
    this.form = this.createForm(emailToPrefill);

    const forcedLocale = this.route.snapshot.queryParams['locale'] ?? null;
    if (forcedLocale) {
      const availableLanguages = this.languagesFacade.getAvailableLanguages();
      const preSelectedLanguage = availableLanguages.find((language: Language) =>
        forcedLocale.startsWith(language.key)
      );
      if (preSelectedLanguage) {
        this.languagesFacade.selectLanguage(preSelectedLanguage);
      }
    }

    this.locales$ = this.localesFacade.getLocales().pipe(take(1), shareReplay(1));

    this.loaded$ = this.locales$.pipe(
      map(() => true),
      shareReplay(1)
    );

    // wait one tick to let the UI evaluate and render so captcha component can be attached
    this.loaded$
      .pipe(
        switchMap(() => timer(1)),
        tap(() => (this.captchaComponent = this.createCaptchaWidget()))
      )
      .subscribe();
  }

  submit(): void {
    if (this.form.invalid) {
      this.toastService.error(
        this.translateService.instant('COMMON.REGISTER.ERROR.ACCOUNT_CREATION_FAILED_TITLE'),
        this.translateService.instant('COMMON.REGISTER.ERROR.PASSWORD_GENERIC_ERROR')
      );
      return;
    }

    this.isRegistering = true;
    this.captchaComponent.startCaptcha();
    this.captchaComponent.captchaSolved
      .pipe(
        switchMap((captcha: string) => {
          const userAccountData: UserAccountRegistration = {
            email: this.form.get('email').value,
            password: this.form.get('password').value,
            firstName: this.form.get('firstName').value,
            lastName: this.form.get('lastName').value,
            salutation: this.form.get('salutation').value,
            locale: this.form.get('locale').value,
            newsletterSubscription: this.form.get('newsletterSubscription').value,
            hubspotCookieValue: this.cookiesService.get('hubspotutk'),
            captcha: captcha,
          };
          return this.userAccountsFacade.createUserAccount(userAccountData);
        })
      )
      .subscribe({
        next: () => {
          this.isRegistering = false;
          this.completed = true;
        },
        error: () => {
          this.isRegistering = false;
          this.captchaComponent.resetCaptcha();
        },
      });
  }

  private createForm(email: string): UntypedFormGroup {
    return this.formBuilder.group(
      {
        email: [{ value: email, disabled: email !== '' }, [Validators.required, StringValidators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(12),
            StringValidators.hasUpperAndLowerCaseLetter,
            StringValidators.hasNumber,
            StringValidators.hasSpecialCharacter,
          ],
        ],
        passwordRepeat: [
          '',
          [
            Validators.required,
            Validators.minLength(12),
            StringValidators.hasUpperAndLowerCaseLetter,
            StringValidators.hasNumber,
            StringValidators.hasSpecialCharacter,
          ],
        ],
        salutation: [null, [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        locale: [null, [Validators.required]],
        newsletterSubscription: [false],
        privacyPolicy: [false, Validators.requiredTrue],
      },
      {
        validators: [RegistrationValidators.matchPassword],
      }
    );
  }

  private createCaptchaWidget(): FriendlyCaptchaPuzzleComponent {
    const authCardElement = this.elementRef.nativeElement.firstChild;
    return this.captchaService.createCaptchaWidget(this.viewContainerRef, authCardElement);
  }
}
