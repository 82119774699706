import { ScriptFile } from '@account/core/models';
import { EnvironmentService, LoadJsScriptService, LocalStorageService } from '@account/core/services';
import { inject, Injectable } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { forkJoin, from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScriptInitializationService {
  private readonly loadJsScriptService = inject(LoadJsScriptService);
  private readonly environmentService = inject(EnvironmentService);
  private readonly localstorageService = inject(LocalStorageService);
  private readonly angulartics2GoogleTagManager = inject(Angulartics2GoogleTagManager);

  initializeScripts(): void {
    forkJoin([this.initializeGoogleTagManager()]).subscribe();
  }

  initializeGoogleTagManager(): Observable<void> {
    const gtmId = this.environmentService.current.googleTagManagerId;
    const gtmScript: ScriptFile = {
      name: 'gtm',
      src: `https://sst.shopware.com/pmthrpcx.js?st=${gtmId}`,
      loaded: false,
      attributes: [{ 'data-usercentrics': 'Google Tag Manager' }],
    };

    const gtagInit: ScriptFile = {
      name: 'gtagInit',
      text: `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', '${gtmId}');
    `,
      loaded: false,
    };
    const gtmScriptLoaded$ = from(this.loadJsScriptService.loadScript(gtmScript)).pipe(
      map((state: { script: string; loaded: boolean; status: string }) => state.loaded)
    );
    const gtagInitScriptLoaded$ = from(this.loadJsScriptService.loadScript(gtagInit)).pipe(
      map((state: { script: string; loaded: boolean; status: string }) => state.loaded)
    );

    return forkJoin([gtmScriptLoaded$, gtagInitScriptLoaded$]).pipe(
      tap(() => this.angulartics2GoogleTagManager.startTracking()),
      map(() => void 0)
    );
  }
}
