<account-auth-card *ngIf="success !== undefined">
  <div class="verification-container">
    <a routerLink="/front" class="link-back">
      <account-sw-icon icon="chevron-left-s" size="16px" />
      {{ 'FRONT.BACK_TO_LOGIN' | translate }}
    </a>

    <ng-container *ngIf="success; else noSuccess">
      <div class="content-container">
        <account-status type="success" [large]="true" />
        <strong>{{ 'FRONT.REGISTER.VERIFICATION.SUCCESS_TITLE' | translate }}</strong>
        <p>{{ 'FRONT.REGISTER.VERIFICATION.SUCCESS' | translate }}</p>
      </div>
    </ng-container>

    <ng-template #noSuccess>
      <div class="content-container">
        <account-status type="error" [large]="true" />
        <strong>{{ 'FRONT.REGISTER.VERIFICATION.ERROR_TITLE' | translate }}</strong>
        <p>{{ 'FRONT.REGISTER.VERIFICATION.ERROR' | translate }}</p>
      </div>
    </ng-template>
  </div>
</account-auth-card>
