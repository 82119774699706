import { CompaniesFacade, FinancialFacade } from '@account/core/facades';
import { AutomaticBalance, Company, CompanyAllocations, CompanyContractor, PaymentMean } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, EMPTY, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { CompanyOnboardingFacade } from '../../facades/onboarding/company-onboarding.facade';
import { CompanyOnboardingDTO } from '../../models/onboarding/onboarding.model';
import * as actions from './onboarding.actions';
import { CompanyOnboardingState } from './onboarding.state';

@Injectable({
  providedIn: 'root',
})
export class OnboardingEffects {
  private readonly actions$ = inject(Actions);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly financialFacade = inject(FinancialFacade);
  private readonly companyOnboardingFacade = inject(CompanyOnboardingFacade);

  setCompanyOnboardingStateForCompanyUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setOnboardingDataForCompany),
      switchMap(() => this.companiesFacade.findCompany().pipe(take(1))),
      switchMap((company: Company | undefined) => {
        if (!company) {
          return EMPTY;
        }

        return combineLatest([
          this.companiesFacade.getAllocations().pipe(
            take(1),
            map((allocations: CompanyAllocations) => allocations.hasShops)
          ),
          this.financialFacade.getAutomaticBalance().pipe(
            take(1),
            map((automaticBalance: AutomaticBalance) => automaticBalance.selectedPaymentMean)
          ),
          this.companyOnboardingFacade.getCompanyOnboarding(company.id).pipe(take(1)),
          of(company.contractor === CompanyContractor.SHOPWARE_INC),
        ]);
      }),
      switchMap(
        ([hasShop, defaultPaymentMean, onboarding, isUsCustomer]: [
          boolean,
          PaymentMean | null,
          CompanyOnboardingDTO,
          boolean,
        ]) =>
          of({
            status: onboarding.status,
            data: {
              isFirstStoreRegistered: true === hasShop,
              isDefaultPaymentMeanRegistered: defaultPaymentMean !== null || isUsCustomer,
              hasVisitedExtensionStore: onboarding.hasVisitedExtensionStore,
              hasExploredShopwarePlans: onboarding.hasExploredShopwarePlans,
            },
          } as CompanyOnboardingState)
      ),

      map((companyOnboardingState: CompanyOnboardingState | null) =>
        actions.setOnboardingDataForCompanySuccess({
          payload: { companyOnboardingState },
        })
      )
    )
  );
}
