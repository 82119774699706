import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastService } from '../../shared/components/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class NoWritePermissionsInterceptor implements HttpInterceptor {
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: Error) => {
        if (error instanceof HttpErrorResponse && error?.error.code === 'UsersException-18') {
          this.toastService.error(
            this.translateService.instant('COMMON.TOAST.ERROR.TITLE.REQUEST_FAILED'),
            this.translateService.instant('COMMON.TOAST.ERROR.MESSAGE.NO_WRITE_PERMISSION.BANNED')
          );
          // immediately complete, to prevent creation of other toast messages
          return EMPTY;
        }
        return throwError(() => error);
      })
    );
  }
}
