import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CompaniesGateway } from '../../../gateways/company/companies.gateway';
import { Company, SbpException } from '../../../models';
import { LoggingService, SessionCookieService } from '../../../services';
import { RootState } from '../../root.state';
import * as sessionActions from '../../session/session.actions';
import * as allocationsActions from '../allocations/allocations.actions';
import * as signedGTCActions from '../signed-gtc/signed-gtc.actions';
import * as supportTicketUpdateActions from '../support-ticket-updates/support-ticket-updates.actions';
import * as companyActions from './company.actions';

@Injectable({
  providedIn: 'root',
})
export class CompanyEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly companiesGateway = inject(CompaniesGateway);
  private readonly sessionCookieService = inject(SessionCookieService);
  getCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.getCompany),
      map((action) => action.payload),
      switchMap((companyId: number) =>
        this.companiesGateway.getCompany(companyId).pipe(
          tap((company: Company) => {
            LoggingService.setCompanyContextForErrorHandler(company);
          }),
          concatMap((company: Company) => {
            const gtcAction =
              company.isAllowedToBypassGTCSignature || this.sessionCookieService.hasGTCBypassCookie()
                ? sessionActions.setBypassGTCConfirmation({ payload: true })
                : signedGTCActions.getSignedGTC({ payload: company.id });
            return [
              sessionActions.setCompanyId({ payload: company.id }),
              companyActions.getCompanySuccess({ payload: company }),
              allocationsActions.getCompanyAllocations({ payload: company.id }),
              supportTicketUpdateActions.getSupportTicketUpdates({ payload: company.id }),
              gtcAction,
            ];
          }),
          catchError((error: SbpException) => of(companyActions.getCompanyFail({ payload: error }))),
          takeUntil(this.actions$.pipe(ofType(sessionActions.logout)))
        )
      )
    )
  );

  noCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.noCompanyExists),
      mergeMap(() => [allocationsActions.noCompanyAllocationsExists(), signedGTCActions.noGTCExists()])
    )
  );
}
