import { AuthFacade } from '@account/core/facades';
import { sessionSelectors } from '@account/core/store/selectors';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CompaniesGateway } from '../../../gateways/company/companies.gateway';
import { LastApprovedGtc, SbpException } from '../../../models';
import { RootState } from '../../root.state';
import * as actions from './signed-gtc.actions';

@Injectable({
  providedIn: 'root',
})
export class SignedGTCEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly authFacade = inject(AuthFacade);
  private readonly companiesGateway = inject(CompaniesGateway);
  getGTCSigned$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getSignedGTC),
      map((action) => action.payload),
      switchMap((companyId: number) =>
        this.companiesGateway.getLastApprovedGTC(companyId).pipe(
          switchMap((lastApprovedGtc: LastApprovedGtc) => {
            const getSignedGTCSuccess$ = actions.getSignedGTCSuccess({ payload: lastApprovedGtc });
            if (lastApprovedGtc?.document.isCurrentVersion) {
              return of(getSignedGTCSuccess$);
            }
            return this.store.select(sessionSelectors.isAllowedToBypassGTCConfirmation).pipe(
              switchMap((isAllowedToBypassGTCConfirmation: boolean) => {
                if (isAllowedToBypassGTCConfirmation) {
                  return of(getSignedGTCSuccess$);
                }
                return this.authFacade.isUILoginCompleted().pipe(
                  map((loginCompleted: boolean) => {
                    if (loginCompleted) {
                      this.authFacade.resetUILogin();
                    }
                    return getSignedGTCSuccess$;
                  })
                );
              })
            );
          }),
          catchError((error: SbpException) => of(actions.getSignedGTCFail({ payload: error })))
        )
      )
    )
  );
}
