import { AuthFacade } from '@account/core/facades';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMapTo, tap } from 'rxjs/operators';

import { ToastService } from '../../shared/components/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class UserTokenExceptionInterceptor implements HttpInterceptor {
  private readonly router = inject(Router);
  private readonly authFacade = inject(AuthFacade);
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);
  private readonly logout$ = new Subject<void>();

  constructor() {
    this.logout$
      .pipe(
        switchMapTo(this.authFacade.logout()),
        tap(() => {
          this.authFacade.setRedirectUrl(this.router.routerState.snapshot.url);
          this.router.navigate(['front']).then(() => {
            this.toastService.error(
              this.translateService.instant('CORE.SESSION_EXPIRED.TOAST.TITLE'),
              this.translateService.instant('CORE.SESSION_EXPIRED.TOAST.MESSAGE')
            );
          });
        })
      )
      .subscribe();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: Error) => {
        const error$ = throwError(() => error);

        if (!(error instanceof HttpErrorResponse)) {
          return error$;
        }

        if (!error.error || error.error.code !== 'UserTokensException-1') {
          return error$;
        }

        /**
         * execute logout in a separate subscription
         * if the root of the interception is a request in a view (and not e.g. an ngrx-effect)
         * the subscription will be lost upon logout, causing the redirect happening after logout not being executed
         */
        this.logout$.next();

        return error$;
      })
    );
  }
}
