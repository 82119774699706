import { LanguagesFacade } from '@account/core/facades';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AcceptLanguageInterceptor implements HttpInterceptor {
  private readonly languagesFacade = inject(LanguagesFacade);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if request is internal url (e.g. /assets) do not enrich request header
    if (!req.url.match(/^(?:[+a-z]+:)?\/\//)) {
      return next.handle(req);
    }

    const request = req.clone({
      headers: req.headers.set('Accept-Language', this.languagesFacade.selectedLanguage.key),
    });
    return next.handle(request);
  }
}
